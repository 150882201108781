import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import imgAb1 from '../assets/img/ab-img-1.webp';
import imgAb3 from '../assets/img/ab-img-3.webp';

import logoWhite from '../assets/img/logo-white.png';

const AboutUs = () => {
    const navigate = useNavigate();

    const [animationNavDesktop, setAnimationNavDesktop] = useState('move-3');
    const [animationNavHome, setAnimationNavHome] = useState(null);
    const [animationNavAboutUs, setAnimationNavAboutUs] = useState(null);
    const [animationNavMeetTheTeam, setAnimationNavMeetTheTeam] = useState(null);
    const [animationNavTac, setAnimationNavTac] = useState(null);
    const [animationAboutUs, setAnimationAboutUs] = useState('fade-2s');
    const [animationOurVision, setAnimationOurVision] = useState('fade-2s');
    const [animationOurVisionBg, setAnimationOurVisionBg] = useState('fade-2s');
    const [animationOurWork, setAnimationOurWork] = useState('fade-2s');
    const [animationP1, setAnimationP1] = useState('fade-2s');
    const [animationP2, setAnimationP2] = useState('fade-2s');
    const [animationP3, setAnimationP3] = useState('fade-2s');
    const [animationP4, setAnimationP4] = useState('fade-2s');
    const [animationP5, setAnimationP5] = useState('fade-2s');
    const [animationP6, setAnimationP6] = useState('fade-2s');
    const [animationImg, setAnimationImg] = useState('fade-2s');
    const [animationFooterContainer, setAnimationFooterContainer] = useState('fade-2s');
    const [animationFooterLogo, setAnimationFooterLogo] = useState('fade-2s');
    const [animationFooterHome, setAnimationFooterHome] = useState('fade-2s');
    const [animationFooterAboutUs, setAnimationFooterAboutUs] = useState('fade-2s');
    const [animationFooterMeetTheTeam, setAnimationFooterMeetTheTeam] = useState('fade-2s');
    const [animationFooterTermsAndConditions, setAnimationFooterTermsAndConditions] = useState('fade-2s');
    const [animationFooterContactInfo, setAnimationFooterContactInfo] = useState('fade-2s');
    const [animationFooterAllRR, setAnimationFooterAllRR] = useState('fade-2s');

    const [imageAlter, setImageAlter] = useState(0);
    const [textAlter, setTextAlter] = useState(0);
    
    const [isNavHidden, setIsNavHidden] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    
    const containerRef = useRef(null);

    const clickedLink = async (page) => {
        switch (page) {
            case 'homeNav':
                setAnimationNavAboutUs('fade-away');
                setAnimationP3('fade-away');
                setAnimationP6('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationOurVision('fade-away');
                setAnimationOurWork('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP4('fade-away');
                setAnimationP1('fade-away');
                setAnimationImg('fade-away');
                setAnimationFooterContainer('fade-away');
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP2('fade-away');
                setAnimationP5('fade-away');
                setAnimationNavMeetTheTeam('fade-away');
                setAnimationFooterAllRR('fade-away');
                setAnimationAboutUs('fade-away');
                setAnimationOurVisionBg('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavHome('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (300)));
                navigate('/');
                break;
            case 'meetTheTeamNav':
                setAnimationNavAboutUs('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationP3('fade-away');
                setAnimationP6('fade-away');
                setAnimationOurVision('fade-away');
                setAnimationOurWork('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP4('fade-away');
                setAnimationP1('fade-away');
                setAnimationImg('fade-away');
                setAnimationFooterContainer('fade-away');
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP2('fade-away');
                setAnimationP5('fade-away');
                setAnimationNavHome('fade-away');
                setAnimationFooterAllRR('fade-away');
                setAnimationAboutUs('fade-away');
                setAnimationOurVisionBg('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavMeetTheTeam('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (300)));
                navigate('/team');
                break;
            case 'termsAndConditionsNav':
                setAnimationNavAboutUs('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationP3('fade-away');
                setAnimationP6('fade-away');
                setAnimationOurVision('fade-away');
                setAnimationOurWork('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP4('fade-away');
                setAnimationP1('fade-away');
                setAnimationImg('fade-away');
                setAnimationNavHome('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP2('fade-away');
                setAnimationP5('fade-away');
                setAnimationFooterContainer('fade-away');
                setAnimationNavMeetTheTeam('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationFooterAllRR('fade-away');
                setAnimationAboutUs('fade-away');
                setAnimationOurVisionBg('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationNavTac('fade-away-2');
                setAnimationNavDesktop('move-away-2c');
                await new Promise(resolve => setTimeout(resolve, (400)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (300)));
                navigate('/terms-and-conditions');
                break;
            case 'home':
                setAnimationFooterAboutUs('fade-away');
                setAnimationImg('fade-away');
                setAnimationP3('fade-away');
                setAnimationP6('fade-away');
                setAnimationOurVision('fade-away');
                setAnimationOurWork('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP4('fade-away');
                setAnimationP1('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationNavDesktop('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP2('fade-away');
                setAnimationP5('fade-away');
                setAnimationAboutUs('fade-away');
                setAnimationNavTac('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationFooterTermsAndConditions('fade-away');
                setAnimationFooterContactInfo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationFooterMeetTheTeam('fade-away');
                setAnimationFooterAllRR('fade-away');
                setAnimationOurVisionBg('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterHome('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/');
                break;
            case 'meetTheTeam':
                setAnimationFooterHome('fade-away');
                setAnimationImg('fade-away');
                setAnimationP3('fade-away');
                setAnimationP6('fade-away');
                setAnimationOurVision('fade-away');
                setAnimationOurWork('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP4('fade-away');
                setAnimationP1('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationNavDesktop('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP2('fade-away');
                setAnimationP5('fade-away');
                setAnimationNavTac('fade-away');
                setAnimationAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationFooterTermsAndConditions('fade-away');
                setAnimationFooterContactInfo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationFooterAboutUs('fade-away');
                setAnimationFooterAllRR('fade-away');
                setAnimationOurVisionBg('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterMeetTheTeam('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/team');
                break;
            case 'termsAndConditions':
                setAnimationFooterHome('fade-away');
                setAnimationImg('fade-away');
                setAnimationP3('fade-away');
                setAnimationP6('fade-away');
                setAnimationOurVision('fade-away');
                setAnimationOurWork('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP4('fade-away');
                setAnimationP1('fade-away');
                setAnimationFooterLogo('fade-away');
                setAnimationNavDesktop('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationP2('fade-away');
                setAnimationP5('fade-away');
                setAnimationNavTac('fade-away');
                setAnimationAboutUs('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationFooterMeetTheTeam('fade-away');
                setAnimationFooterContactInfo('fade-away');
                await new Promise(resolve => setTimeout(resolve, (130)));
                setAnimationFooterAboutUs('fade-away');
                setAnimationFooterAllRR('fade-away');
                setAnimationOurVisionBg('fade-away');
                await new Promise(resolve => setTimeout(resolve, (400)));
                setAnimationFooterTermsAndConditions('fade-away-2');
                setAnimationFooterContainer('move-away-2');
                await new Promise(resolve => setTimeout(resolve, (500)));
                window.scrollTo(0, 0);
                await new Promise(resolve => setTimeout(resolve, (500)));
                navigate('/terms-and-conditions');
                break;
        }
    }

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const handleScroll = () => {
            const scrollTop = container.scrollTop;
            const scrollHeight = container.scrollHeight - container.clientHeight;
            const percentage = (scrollTop / scrollHeight) * 100;
    
            if (percentage < 20) {
                document.getElementById('about-us-container').style.transform = "translateX(0)";
                document.getElementById('about-us-image-container').style.transform = "translateX(0)";
                document.getElementById('about-us-page-image').style.opacity = "100%";
            } else if (percentage <= 40) {
                if (percentage <= 30) {
                    setTextAlter(0);
                } else {
                    setTextAlter(1);
                }
                const scrollRange = 40 - 20;
                const currentScrollPositionInRange = percentage - 20;
                const animationPercentage = currentScrollPositionInRange / scrollRange;
                const translateX = animationPercentage * 100;
                const translateX2 = 1 / (1 + Math.exp(-((translateX / 10) - 5)));
                document.getElementById('about-us-container').style.transform = `translateX(${translateX2 * 44}vw)`;
                document.getElementById('about-us-image-container').style.transform = `translateX(${translateX2 * -44}vw)`;
                document.getElementById('about-us-page-image').style.opacity = `${100 - translateX2 * 100}%`;
            } else if (percentage < 60 ) {
                if (percentage < 50) {
                    setImageAlter(0);
                } else {
                    setImageAlter(1);
                }
                document.getElementById('about-us-container').style.transform = "translateX(44vw)";
                document.getElementById('about-us-image-container').style.transform = "translateX(-44vw)";
                document.getElementById('about-us-page-image').style.opacity = "0%";
            } else if (percentage <= 80) {
                if (percentage <= 70) {
                    setTextAlter(1);
                } else {
                    setTextAlter(2);
                }
                const scrollRange = 80 - 60;
                const currentScrollPositionInRange = percentage - 60;
                const animationPercentage = currentScrollPositionInRange / scrollRange;
                const translateX = animationPercentage * 100;
                const translateX2 = 1 / (1 + Math.exp(-((translateX / 10) - 5)));
                document.getElementById('about-us-container').style.transform = `translateX(${44 - translateX2 * 44}vw)`;
                document.getElementById('about-us-image-container').style.transform = `translateX(${ -44 - translateX2 * -44}vw)`;
                document.getElementById('about-us-page-image').style.opacity = `${translateX2 * 100}%`;
            } else {
                document.getElementById('about-us-container').style.transform = "translateX(0)";
                document.getElementById('about-us-image-container').style.transform = "translateX(0)";
                document.getElementById('about-us-page-image').style.opacity = "100%";
            }
        };
        container.addEventListener('scroll', handleScroll);
    
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (prevScrollPos > currentScrollPos) {
                if (isNavHidden) {
                    navBar.style.transform = 'translateY(0)';
                    setIsNavHidden(false);
                }
            } else {
                if (!isNavHidden) {
                    navBar.style.transform = 'translateY(-100%)';
                    setIsNavHidden(true);
                }
            }
            setPrevScrollPos(currentScrollPos);
        };

        const navBar = document.querySelector('nav');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavHidden, prevScrollPos,]);

    const renderText = () => {
        switch (textAlter) {
            case 1: 
                return (
                    <div id="about-us-container" className="d-flex flex-column align-items-center justify-content-center h-100">
                        <h1>Our vision</h1>
                        <p>
                            We're committed to shaping a future where design integrates with the 
                            natural environment. 
                        </p>
                        <p>
                            Our goal is to transform not only physical spaces but also 
                            the way people interact with them.
                        </p>
                        <div className="section-margin-5"></div>
                    </div>
                );
            case 2: 
                return (
                    <div id="about-us-container" className="d-flex flex-column align-items-center justify-content-center h-100">
                        <h1>Our work</h1>
                        <p>
                            For us, you are not just another client. We are commited to make the best out of
                            each design. We pride ourselves on delivering innovative solutions that surpass 
                            your expectations.
                        </p>
                    <div className="section-margin-5"></div>
                </div>
                );
            default: 
                return (
                    <div id="about-us-container" className="d-flex flex-column align-items-center justify-content-center h-100">
                        <h1>About us</h1>
                        <p>
                            Siltstone represents a fusion of architectural expertise and sustainable values.
                            Our team specializes in residential projects, infusing every design with a blend 
                            of innovation and functionality.
                        </p>
                        <div className="section-margin-5"></div>
                    </div>
                );
        }
    }

    const renderImg = () => {
        switch (imageAlter) {
            case 1: 
                return (
                    <div id="about-us-page-image-bg" className="w-100">
                        <img id="about-us-page-image" className="w-100 h-100" src={imgAb3} alt="img-3" />
                    </div>
                );
            default: 
                return (
                    <div id="about-us-page-image-bg" className="w-100">
                        <img id="about-us-page-image" className="w-100 h-100" src={imgAb1} alt="img-3" />
                    </div>
                );
        }
    }

    return (
        <div id="about-us-page" className="d-flex flex-column align-items-center">
            <nav id="nav-desktop" className={`${animationNavDesktop} c-1-bg d-flex flex-column w-100`}>
                <div id="nav-desktop-container" className="d-flex align-items-center justify-content-end w-100">
                    <button className={`${animationNavHome} c-8 h-100`} onClick={() => clickedLink('homeNav')}>Home</button>
                    <button className={`${animationNavAboutUs} c-8 h-100`} onClick={() => clickedLink('aboutUsNav')}>About us</button>
                    <button className={`${animationNavMeetTheTeam} c-8 h-100`} onClick={() => clickedLink('meetTheTeamNav')}>Team</button>
                    <button id="terms-button-nav" className={`${animationNavTac} c-8 h-100`} onClick={() => clickedLink('termsAndConditionsNav')}>Terms & Conditions</button>
                </div>
            </nav>
            <div ref={containerRef} id="whole-page-container" className="w-100">
                <div id="sticky" className={animationAboutUs}>
                    {renderText()}
                    <div id="about-us-image-container" className={`${animationImg} d-flex flex-column justify-content-center h-100`}>
                        {renderImg()}
                    </div>
                </div>
                <div className="height-300"></div>
            </div>
            <div id="whole-page-mobile" className="w-100 flex-column align-items-center">
                <div className="section-margin-nav"></div>
                <div className="section-about-us-mobile d-flex flex-column align-items-center justify-content-center w-100">
                    <h1 className={animationAboutUs}>About us</h1>
                    <p className={animationP1}>
                        Siltstone represents a fusion of architectural expertise and sustainable values.
                    </p>
                    <p className={animationP2}>
                        Our team specializes in residential projects, infusing every design with a blend 
                        of innovation and functionality.
                    </p>
                </div>
                <div id="about-us-bg-mobile" className={`${animationOurVisionBg} section-about-us-mobile d-flex flex-column align-items-center justify-content-center w-100`}>
                    <h1 className={animationOurVision}>Our vision</h1>
                    <p className={animationP3}>
                        We're committed to shaping a future where design integrates with the 
                        natural environment. 
                    </p>
                    <p className={animationP4}>
                        Our goal is to transform not only physical spaces but also 
                        the way people interact with them.
                    </p>
                </div>
                <div className="section-about-us-mobile d-flex flex-column align-items-center justify-content-center w-100">
                    <h1 className={animationOurWork}>Our work</h1>
                    <p className={animationP5}>
                        For us, you are not just another client. We are commited to make the best out of
                        each design.
                    </p>
                    <p className={animationP6}>
                        We pride ourselves on delivering innovative solutions that surpass 
                        your expectations.
                    </p>
                </div>
            </div>
            <div id="footer" className={`${animationFooterContainer} w-100 c-8-bg d-flex flex-column align-items-center`}>
                <div className="section-margin-footer w-100 c-6-bg"></div>
                <div id="footer-content" className="d-flex align-items-start justify-content-center w-100">
                    <div id="footer-mobile-div"></div>
                    <div className="footer-section d-flex justify-content-center">
                        <img id="logo-img-footer" className={animationFooterLogo} src={logoWhite} alt="logo" />
                    </div>
                    <div id="footer-mobile-div"></div>
                    <div className="footer-section d-flex justify-content-center">
                        <div className="d-flex flex-column">
                            <button className={`${animationFooterHome} white-1`} onClick={() => clickedLink('home')}>Home</button>
                            <button className={`${animationFooterAboutUs} white-1`} onClick={() => clickedLink('aboutUs')}>About us</button>
                            <button id="team-button" className={`${animationFooterMeetTheTeam} white-1`} onClick={() => clickedLink('meetTheTeam')}>Meet the team</button>
                            <button id="terms-button" className={`${animationFooterTermsAndConditions} white-1`} onClick={() => clickedLink('termsAndConditions')}>Terms & Conditions</button>
                        </div>
                    </div>
                    <div id="footer-container-contact" className="footer-section justify-content-center">
                        <div className={`${animationFooterContactInfo} d-flex flex-column justify-content-top white-1`}>
                            <h3 id="contact-adress">608 16th St, Oakland, CA 94612, USA</h3>
                            <h3>510-555-0198</h3>
                            <h3>contact@siltstone.com</h3>
                        </div>
                    </div>
                </div>
                <h5 id="all-r-reserved" className={`${animationFooterAllRR} c-5 footer-bottom`}>All rights reserved | Siltstone Design Group</h5>
                <h5 className={`${animationFooterAllRR} c-5 footer-bottom pb-4`}>Made by Ivo Gonsé</h5>
            </div>
        </div>
    );
}

export default AboutUs;